angular.module('app')

    /**
    * The notification directive
    * @author: Simone Zennaro
    */
    .directive('notification', ['$window','$translate', 'moment', 'LibraryApplicationData', ($window:any, $translate:angular.translate.ITranslateService, moment:any, LibraryApplicationData: any) => {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                notification: '=info'
            },
            templateUrl: 'app/shared/notification/notification.html',
            link: (scope:any, element:any, attributes:any) => {
                // Collegamento all'oggetto principale
                scope.libraryApplicationData = LibraryApplicationData;
                
                scope.notificationAction = (action:any) => {
                    alert("Notification action " + action);
                }
                scope.getElapsedTime = (creationDate:any):string => {
                    return moment(creationDate).fromNow();

                }
                scope.notificationClick = () => {
                    scope.$emit('notificationClick');
                }
            }
        };
    }]);